import { MenubarComponent } from "@/store/user/types";

const menus: MenubarComponent = [
  {
    label: "Analytics",
    icon: "fas fa-chart-bar",
    class: "cd-46 analytics",
    to: { name: "analytics" },
    permission: "menu/analytics:::get",
  },
  {
    label: "Agenda",
    icon: "fas fa-calendar-day",
    class: "cd-46 calendar",
    to: { name: "home-calendar" },
    permission: "menu/agenda:::get",
  },
  {
    label: "Processos",
    icon: "fas fa-gavel",
    class: "cd-47 process",
    to: { name: "process" },
    permission: "menu/processos:::get",
  },
  {
    label: "Jurídico",
    icon: "fas fa-chalkboard-teacher",
    class: "cd-10",
    items: [
      {
        label: "Clientes",
        icon: "fas fa-user",
        class: "cd-47 lawsuit-customer sub-10",
        to: { name: "lawsuit-customer" },
        permission: "menu/juridico/clientes:::get",
      },
      {
        label: "Teses",
        icon: "fas fa-book-open-reader",
        class: "cd-20 theses-bank sub-10",
        to: { name: "juridical-theses-bank" },
        permission: "menu/teses/teses:::get",
      },
      {
        label: "Foros",
        icon: "fas fa-landmark",
        class: "cd-7 forum sub-10",
        to: { name: "forum" },
        permission: "menu/juridico/foros:::get",
      },
      {
        label: "Áreas",
        icon: "fas fa-network-wired",
        class: "cd-17 areas sub-10",
        to: { name: "areas" },
        permission: "menu/juridico/areas:::get",
      },
      {
        label: "Instâncias",
        icon: "fas fa-people-arrows",
        class: "cd-18 instancia sub-10",
        to: { name: "instancia" },
        permission: "menu/juridico/instancias:::get",
      },
      {
        label: "Fases",
        icon: "fas fa-layer-group",
        class: "cd-20 fase sub-10",
        to: { name: "fase" },
        permission: "menu/juridico/fases:::get",
      },
      {
        label: "Tipos de Ação",
        icon: "fas fa-location-crosshairs",
        class: "cd-20 action-type sub-10",
        to: { name: "juridical-action-type" },
        permission: "menu/teses/tipo-acao:::get",
      },
      {
        label: "Causas da Ação",
        icon: "fas fa-suitcase",
        class: "cd-20 action-cause sub-10",
        to: { name: "juridical-action-cause" },
        permission: "menu/teses/causa-acao:::get",
      },
    ],
  },
  {
    label: "Integração",
    icon: "fas fa-network-wired",
    class: "9 integration",
    permission: "menu/integracao:::get",
    to: { name: "integration" },
  },
  {
    label: "Logística",
    icon: "fas fa-user-friends",
    class: "cd-13 logistica",
    items: [
      {
        label: "Audiências",
        icon: "fas fa-balance-scale",
        class: "cd-1 audiencia sub-13",
        to: { name: "audiencia" },
        permission: "menu/logistica/audiencias:::get",
      },
      {
        label: "Diligências",
        icon: "far fa-handshake",
        class: "cd-2 diligencia sub-13",
        to: { name: "diligencia" },
        permission: "menu/logistica/diligencias:::get",
      },
      {
        label: "Clientes",
        icon: "fas fa-user",
        class: "cd-5 logistics-customer sub-13",
        to: { name: "logistics-customer" },
        permission: "menu/logistica/clientes:::get",
      },
      {
        label: "Correspondentes",
        icon: "fas fa-user-tie",
        class: "cd-4 logistics-correspondent sub-13",
        to: { name: "logistics-correspondent" },
        permission: "menu/logistica/correspondentes:::get",
      },
      {
        label: "Tipos",
        icon: "fas fa-list",
        class: "cd-14 logistics-acttype sub-13",
        to: { name: "logistics-acttype" },
        permission: "menu/logistica/tipos:::get",
      },
    ],
  },
  {
    label: "Solicitações",
    icon: "fas fa-file-contract",
    class: "cd-38 requests",
    to: { name: "requests" },
    permission: "menu/solicitacoes:::get",
  },
  {
    label: "Honorários",
    icon: "fas fa-calendar",
    class: "cd-38 requests",
    to: { name: "honorary" },
    permission: "menu/honorarios:::get",
  },
  {
    label: "Cobrança",
    icon: "fas fa-hand-holding-usd",
    class: "cd-48 charge",
    items: [
      {
        label: "Devedores",
        icon: "fas fa-book-dead",
        class: "cd-35 debtor sub-48",
        to: { name: "debtor" },
        permission: "menu/cobranca/devedores:::get",
      },
      {
        label: "Clientes",
        icon: "fas fa-user",
        class: "cd-67 collection-customer sub-48",
        to: { name: "collection-customer" },
        permission: "menu/cobranca/clientes:::get",
      },
      {
        label: "Tipos de débito",
        icon: "fas fa-question-circle",
        class: "cd-59 collection-debit-types sub-48",
        to: { name: "collection-debit-types" },
        permission: "menu/cobranca/tipos_debito:::get",
      },
      {
        label: "Regra de cobrança",
        icon: "fas fa-weight",
        class: "cd-61 collection-rule sub-48",
        to: { name: "collection-rule" },
        permission: "menu/cobranca/regra_cobranca:::get",
      },
    ],
  },
  {
    label: "Transações",
    inlineLabel: "Financeiro",
    icon: "fas fa-sack-dollar",
    class: "p-financial-overview cd-999",
    to: { name: "p-financial-overview" },
    permission: "menu/financeiro:::get",
  },
  {
    label: "Financeiro",
    icon: "fas fa-cash-register",
    class: "cd-49",
    items: [
      {
        label: "Fornecedores",
        icon: "fas fa-store",
        class: "p-financial-company-list sub-49",
        to: { name: "p-financial-company-list" },
        permission: "menu/financeiro/favorecidos:::get",
      },
      {
        label: "Prestadores de Serv.",
        icon: "fas fa-user-tie",
        class: "p-financial-provider-list sub-49",
        to: { name: "p-financial-provider-list" },
        permission: "menu/financeiro/favorecidos:::get",
      },
      {
        label: "Clientes",
        icon: "fas fa-user",
        class: "p-financial-customer-list sub-49",
        to: { name: "p-financial-customer-list" },
        permission: "menu/financeiro/favorecidos:::get",
      },
      {
        label: "Parceiros",
        icon: "far fa-handshake",
        class: "p-financial-partner-list sub-49",
        to: { name: "p-financial-partner-list" },
        permission: "menu/financeiro/favorecidos:::get",
      },
      {
        label: "Sócios",
        icon: "fas fa-handshake",
        class: "p-financial-business-partner-list sub-49",
        to: { name: "p-financial-business-partner-list" },
        permission: "menu/financeiro/favorecidos:::get",
      },
      {
        label: "Entidades públicas",
        icon: "fas fa-landmark-flag",
        class: "p-financial-public-entity-list sub-49",
        to: { name: "p-financial-public-entity-list" },
        permission: "menu/financeiro/favorecidos:::get",
      },
      {
        label: "Colaboradores",
        icon: "fas fa-building-user",
        class: "p-financial-collaborator-list sub-49",
        to: { name: "employee" },
        permission: "menu/financeiro/favorecidos:::get",
      },
      {
        label: "Retenções",
        icon: "fas fa-percent",
        class: "p-financial-retention-list sub-49",
        to: { name: "p-financial-retention-list" },
        permission: "menu/financeiro/tipos_cobranca:::get",
      },
      {
        label: "Categorias",
        icon: "fas fa-layer-group",
        class: "p-financial-category-list sub-49",
        to: { name: "p-financial-category-list" },
        permission: "menu/financeiro/tipos_cobranca:::get",
      },
      {
        label: "Contas bancárias",
        icon: "fas fa-building-columns",
        class: "p-financial-bank-list sub-49",
        to: { name: "p-financial-bank-list" },
        permission: "menu/financeiro/moedas:::get",
      },
      {
        label: "Setores",
        icon: "fas fa-people-group",
        class: "p-financial-sector-list sub-49",
        to: { name: "p-financial-sector-list" },
        permission: "menu/financeiro/centro_custos:::get",
      },
      {
        label: "Estabelecimentos",
        icon: "fas fa-building",
        class: "p-financial-establishment-list sub-49",
        to: { name: "p-financial-establishment-list" },
        permission: "menu/financeiro/centro_custos:::get",
      },
    ],
  },
  {
    label: "Tarefas",
    icon: "fas fa-tasks",
    class: "cd-66 task",
    to: { name: "task" },
    permission: "menu/tarefas:::get",
  },
  {
    label: "Escritório",
    icon: "fas fa-building",
    class: "cd-62 rh",
    items: [
      {
        label: "Colaboradores",
        icon: "fas fa-building-user",
        class: "cd-64 employee sub-62",
        to: { name: "employee" },
        permission: "menu/pessoas/funcionarios:::get",
      },
      {
        label: "Equipes",
        icon: "fas fa-users",
        class: "cd-44 groups-responsible sub-62",
        to: { name: "groups-responsible" },
        permission: "menu/pessoas/equipes:::get",
      },
      {
        label: "Advogados",
        icon: "fas fa-user-tie",
        class: "cd-44 department-attorney groups sub-62",
        to: { name: "department-attorney" },
        permission: "menu/pessoas/advogados:::get",
      },
      {
        label: "Funções",
        icon: "fas fa-briefcase",
        class: "cd-63 occupation sub-62",
        to: { name: "occupation" },
        permission: "menu/pessoas/funcoes:::get",
      },
      {
        label: "Locais de Trabalho",
        icon: "fas fa-building",
        class: "cd-65 workplace sub-62",
        to: { name: "workplace" },
        permission: "menu/pessoas/locais_trabalho:::get",
      },
    ],
  },
  {
    label: "Relatórios",
    icon: "fas fa-file-download",
    class: "cd-88 report",
    items: [
      {
        label: "Honorários",
        icon: "fas fa-money-check-alt",
        class: "cd-90 collection-honorarios sub-88",
        to: { name: "command:::getCollectionFees" },
        permission: "menu/relatorios/honorarios:::get",
      },
      {
        label: "Acordos",
        icon: "fas fa-handshake",
        class: "cd-91 collection-agreements sub-88",
        to: { name: "command:::getCollectionAgreement" },
        permission: "menu/relatorios/acordos:::get",
      },
      {
        label: "Cobrança Geral",
        icon: "fas fa-hand-holding-dollar",
        class: "cd-91 collection-agreements sub-88",
        to: { name: "command:::getCollectionGeneral" },
        permission: "menu/relatorios/cobranca_geral:::get",
      },
      {
        label: "Somente Audiências",
        icon: "fas fa-balance-scale",
        class: "cd-101 logistics-hearing sub-88",
        to: { name: "command:::getCompletedRequestHearing" },
        permission: "menu/relatorios/somente_audiencias:::get",
      },
      {
        label: "Somente Diligências",
        icon: "far fa-handshake",
        class: "cd-100 logistics-diligence sub-88",
        to: { name: "command:::getCompletedRequestDiligence" },
        permission: "menu/relatorios/somente_diligencias:::get",
      },
      {
        label: "Audiências e Diligências",
        icon: "fas fa-gavel",
        class: "cd-100 logistics-hearing sub-88",
        to: { name: "command:::getCompletedAllRequest" },
        permission: "menu/relatorios/todas_solicitacoes:::get",
      },
    ],
  },
  {
    label: "Midgard",
    icon: "fas fa-tools",
    class: "cd-41 midgard",
    url: process.env.VUE_APP_MIDGARD,
    permission: "menu/midgard:::get",
  },
];

export function getMenuLinks() {
  return menus.map((navigationLink) => ({ ...navigationLink, menus: [...(navigationLink.items || [])] }));
}

export function getMenuLink(routeName: string) {
  let navigationLink = null;

  menus.forEach((link) => {
    // if (link.to?.name === routeName) {
    //   navigationLink = link
    //   return
    // }

    link.items?.forEach((menu) => {
      if (menu.to?.name === routeName) {
        navigationLink = menu;
        return;
      }
    });
  });

  return navigationLink || menus[0];
}

export default menus;
