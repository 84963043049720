import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ToastParams } from "../global/types";
import ExpedientAttorneyModule from "./attorney/module";
import { ExpedientService } from "./service";
import { Expedient, ExpedientForm, ExpedientSummary, IntegrationDjenTermFormT, IntegrationDjenTermT } from "./types";
import { Pagination } from "../types";
import gojus from "@/services/gojus";

const VUE_APP_API_PROCESS_URL = process.env.VUE_APP_API_PROCESS_URL;

@Module({ namespaced: true })
export class ExpedientModule extends VuexModule {
  all: any = [];
  summary: ExpedientSummary = {
    fatal_dia: 0,
    lidos_dia: 0,
    nao_lidos: 0,
    pendente_ciencia: 0,
    prazo_findado: 0,
    sem_prazo: 0,
    ultimo_dia_ciencia: 0,
  };

  summaryFilter = "PENDENTE-CIENCIA";
  sortBy = "";
  filters: any = { resumo: "PENDENTE-CIENCIA", attorney_id: null };
  pagination: Pagination = { row: 10, total: 0, current: 1 };
  terms: IntegrationDjenTermT[] = [];

  service = new ExpedientService();

  static modules = {
    attorney: ExpedientAttorneyModule,
  };

  @Mutation
  setTerms(terms: IntegrationDjenTermT[]) {
    this.terms = terms;
  }

  @Mutation
  setPagination(payload: Partial<Pagination>) {
    this.pagination = { ...this.pagination, ...payload };
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = value || { attorney_id: null };
    if (this.summaryFilter) this.filters.resumo = this.summaryFilter;
  }

  @Mutation
  updateAttorneyFilter(value: any) {
    this.filters.attorney_id = value;
  }

  @Mutation
  updateSummaryFilter(value: any) {
    this.summaryFilter = value;
    this.filters.resumo = value;
  }

  @Mutation
  updateSummary(taskSummary: any) {
    for (const key in taskSummary) {
      const newKey = key.replaceAll("-", "_").toLocaleLowerCase();
      taskSummary[newKey] = taskSummary[key];

      delete taskSummary[key];
    }

    this.summary = taskSummary;
  }

  @Mutation
  updateExpedients(expedients: Expedient[]) {
    this.all = expedients;
  }

  @Mutation
  setSortBy(value: string) {
    this.sortBy = value;
  }

  @Action
  async getReportExcel(params: any) {
    const response = await this.service.getReportExcel({ ...this.filters, ...params?.filters });

    return response;
  }

  @Action
  async getItem(payload: { id: number }) {
    try {
      const response: any = await this.service.getAll({ id: payload.id });
      const [expedient] = (response.data || []) as Expedient[];

      expedient.content = expedient.teor;
      expedient.county = expedient.county?.trim() || "";
      expedient.county = expedient.county[0] === "/" ? expedient.county.slice(1) : expedient.county;

      return expedient;
    } catch (error: any) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll(payload?: { filters?: any; commit?: boolean }) {
    try {
      const pagination = {
        page: this.pagination.current,
        nr_por_pagina: this.pagination.row,
        paginacao: true,
      };

      const response = await this.service.getAll({ ...this.filters, ...payload?.filters, ...pagination });
      const expedients = response.data.results || [];

      for (const expedient of expedients) {
        expedient.content = expedient.teor;
        expedient.county = expedient.county?.trim() || "";
        expedient.county = expedient.county[0] === "/" ? expedient.county.slice(1) : expedient.county;
      }

      if (payload?.commit === false) {
        return expedients;
      }

      this.context.commit("setPagination", { total: response?.data?.count || 0 });
      this.context.commit("updateExpedients", expedients);
      this.context.dispatch("getSummary", { filters: payload?.filters });
      this.context.commit("setSortBy", payload?.filters?.ordenar_por || this.sortBy);

      return expedients;
    } catch (error: any) {
      if (error?.status === 404) {
        this.context.commit("setPagination", { current: 1, row: 10, total: 0 });
        return await this.context.dispatch("getList", payload);
      }

      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getSummary(payload?: { filters?: any }) {
    try {
      const filters: any = { ...payload?.filters };
      if (this.filters.attorney_id) filters.attorney_id = this.filters.attorney_id;

      const response = await this.service.getSummary(filters);
      this.context.commit("updateSummary", response);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(params: ExpedientForm) {
    try {
      const data = await this.service.edit(params.cd, params.form);
      this.context.commit("updateCurrent", data);

      const message: ToastParams[] = [{ severity: "success", summary: "Expediente editado com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getTerms() {
    try {
      const response = await gojus.get<IntegrationDjenTermFormT[]>({
        entity: "terms-djen/",
        config: { baseAPI: VUE_APP_API_PROCESS_URL },
      });

      this.context.commit("setTerms", response.data);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async createTerm(payload: { form: IntegrationDjenTermFormT }) {
    try {
      await gojus.post<IntegrationDjenTermT, IntegrationDjenTermFormT>({
        entity: "terms-djen/",
        data: payload.form,
        config: { baseAPI: VUE_APP_API_PROCESS_URL },
      });

      this.context.dispatch("getTerms");

      return { success: true };
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return { success: false };
    }
  }

  @Action
  async editTerm(payload: { id: number; form: Partial<IntegrationDjenTermFormT> }) {
    try {
      await gojus.patch<IntegrationDjenTermT, Partial<IntegrationDjenTermFormT>>({
        entity: `terms-djen/${payload.id}`,
        data: payload.form,
        config: { baseAPI: VUE_APP_API_PROCESS_URL },
      });

      this.context.dispatch("getTerms");

      return { success: true };
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return { success: false };
    }
  }

  @Action
  async removeTerm(payload: { id: number }) {
    try {
      await gojus.delete({
        entity: `terms-djen/${payload.id}`,
        config: { baseAPI: VUE_APP_API_PROCESS_URL },
      });

      this.context.dispatch("getTerms");

      return { success: true };
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return { success: false };
    }
  }
}

export default ExpedientModule;
