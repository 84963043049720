import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ToastParams } from "@/store/global/types";
import { ExpedientAttorney, ExpedientAttorneyParams } from "@/store/expedient/attorney/types";

import { ExpedientAttorneyService } from "@/store/expedient/attorney/service";
import { Pagination } from "@/store/types";

@Module({ namespaced: true })
export class ExpedientAttorneyModule extends VuexModule {
  current: ExpedientAttorney | null = null;
  all: ExpedientAttorney[] = [];
  pagination: Pagination = { row: 10, total: 0, current: 1 };
  filters: any = {};

  service = new ExpedientAttorneyService();

  @Mutation
  updateExpedientAttorney(attorney: ExpedientAttorney) {
    this.current = attorney;
  }

  @Mutation
  updateExpedientAttorneys(lawyers: ExpedientAttorney[]) {
    this.all = lawyers.sort((a, b) => a.name.localeCompare(b.name));
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = value || {};
  }

  @Action
  async get(params: ExpedientAttorneyParams) {
    try {
      const { data } = await this.service.get(params.cd);
      this.context.commit("updateExpedientAttorney", data);

      return data;
    } catch (error: any) {
      if (error.isGojusError && error.status === 404) return;

      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll(params: ExpedientAttorneyParams) {
    try {
      const { data } = await this.service.getAll({ ...this.filters, ...(params?.filters || {}) });
      this.context.commit("updateExpedientAttorneys", data);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async search(params: ExpedientAttorneyParams) {
    try {
      const { data } = await this.service.search({ ...this.filters, ...(params?.filters || {}) });
      this.context.commit("updateExpedientAttorneys", data);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create(params: ExpedientAttorneyParams) {
    try {
      const response = await this.service.create(params.attorney);
      if (response.data?.id)
        for (const account of params.attorney.accounts) {
          await this.service.createAccount({ ...account, attorney: response.data.id });
        }

      const message: ToastParams[] = [{ severity: "success", summary: "Advogado cadastrado com sucesso" }];
      this.context.commit("global/updateToast", message, { root: true });

      this.context.commit("updateExpedientAttorney", response.data);

      return response.data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(params: ExpedientAttorneyParams) {
    try {
      const response = await this.service.edit(params.cd, params.attorney);

      if (response?.data?.id)
        for (const account of params.attorney.accounts) {
          if (account.id) await this.service.editAccount(account.id, { ...account, attorney: response.data.id });
          else await this.service.createAccount({ ...account, attorney: response.data.id });
        }

      const removedAccounts =
        this.current?.accounts.filter(
          (account) => !params.attorney.accounts.find((formAccount) => formAccount.id === account.id)
        ) || [];

      for (const removedAccount of removedAccounts) {
        await this.service.deleteAccount(removedAccount.id);
      }

      const message: ToastParams[] = [{ severity: "success", summary: "Advogado editado com sucesso" }];
      this.context.commit("global/updateToast", message, { root: true });
      this.context.commit("updateExpedientAttorney", response?.data);

      return response?.data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async delete(params: ExpedientAttorneyParams) {
    try {
      const { data } = await this.service.delete(params.cd);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: "Cliente excluído com sucesso",
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });
      this.context.commit("updateExpedientAttorney", { id: 0 });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default ExpedientAttorneyModule;
