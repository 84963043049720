import gojus from "@/services/gojus";

import { Employee, EmployeeForm } from "@/store/department/employee/types";
import { PaginationParams, PaginationResponse } from "@/store/types";

const { VUE_APP_API_DEPARTMENT_URL, VUE_APP_API_AUTH_URL } = process.env;

export class EmployeeService {
  endpoint = "funcionarios/";
  cdEndpoint = (cd: number) => `funcionarios/${cd}/`;
  statusEndpoint = (user: number, company: number) => `/usuarios/inativar-ativar/${user}/${company}/`;

  async get(cd: number) {
    const response = await gojus.get<Employee>({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_DEPARTMENT_URL },
    });

    return response;
  }

  async getAll<TR = PaginationResponse<Employee>>(
    pagination: PaginationParams | null,
    filters: any,
    showInactive = false
  ) {
    if (filters.ie_ativo === undefined && !showInactive) filters.ie_ativo = true;

    const response = await gojus.get<TR>({
      entity: this.endpoint,
      config: {
        query: { ...filters, ...pagination },
        baseAPI: VUE_APP_API_DEPARTMENT_URL,
      },
    });

    return response;
  }

  async create(employee: EmployeeForm) {
    const response = await gojus.post<Employee, EmployeeForm>({
      entity: this.endpoint,
      data: employee,
      config: { baseAPI: VUE_APP_API_DEPARTMENT_URL },
    });

    return response;
  }

  async edit(cd: number, employee: Partial<EmployeeForm>) {
    const response = await gojus.patch<Employee, Partial<EmployeeForm>>({
      entity: this.cdEndpoint(cd),
      data: employee,
      config: { baseAPI: VUE_APP_API_DEPARTMENT_URL },
    });

    return response;
  }

  async status(cd: number, active: boolean) {
    const response = await gojus.get({
      entity: this.statusEndpoint(cd, Number(localStorage.getItem("CD_EMPRESA"))),
      config: { query: { type_action: active ? "1" : "0" }, baseAPI: VUE_APP_API_AUTH_URL },
    });

    return response;
  }

  async remove(cd: number) {
    const response = await gojus.delete({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_DEPARTMENT_URL },
    });
    return response;
  }
}

export default EmployeeService;
