import store from "@/store";
import { Employee } from "@/store/department/employee/types";
import { Forum } from "@/store/forum/types";
import { Lawsuit, ProcessingSystem } from "@/store/lawsuit/types";
import { useAppTagStore } from "@/stores/app/tag";
import { AppTagT } from "@/stores/app/tag/types";

export function useLawsuitUtils() {
  async function extendsLawsuitsTags(lawsuits: Lawsuit[]) {
    const appTagStore = useAppTagStore();

    async function retrieveTags(lawsuit: Lawsuit) {
      const tags: AppTagT[] = [];

      async function retrieveTag(tagId: string) {
        const tag = await appTagStore.retrieve({ id: Number(tagId), cache: true });
        if (tag) {
          tags.push(tag);
        }
      }

      await Promise.allSettled(lawsuit.etiquetas?.map(retrieveTag) || []);

      return tags;
    }

    async function extendsLawsuitTags(lawsuit: Lawsuit) {
      lawsuit.tags = await retrieveTags(lawsuit);
    }

    await Promise.allSettled(lawsuits.map(extendsLawsuitTags));
  }

  async function extendsLawsuitsResponsible(lawsuits: Lawsuit[]) {
    async function retrieveEmployees(lawsuits: Lawsuit[]) {
      const allEmployeeIds = lawsuits.map((lawsuit) => lawsuit.cd_responsavel_tarefa);
      const employeeIds = [...new Set(allEmployeeIds.filter(Boolean))];

      if (!employeeIds.length) {
        return {};
      }

      const employees: Employee[] = await store.state.department.employee.getAll(null, {
        filters: { cd_authuser: employeeIds },
      });

      return employees.reduce((acc, employee) => {
        acc[String(employee.cd_authuser)] = employee;
        return acc;
      }, {} as Record<string, Employee>);
    }

    const employees: Record<string, Employee> = await retrieveEmployees(lawsuits);

    lawsuits.forEach((lawsuit) => {
      lawsuit.ob_responsavel_tarefa = employees[String(lawsuit.cd_responsavel_tarefa)] || null;
    });
  }

  async function extendsLawsuitsCity(lawsuits: Lawsuit[]) {
    async function retrieveCity(lawsuit: Lawsuit) {
      if (lawsuit.cd_cidade && !lawsuit.nm_cidade) {
        lawsuit.ob_cidade = await store.dispatch("location/getCity", { cd_cidade: lawsuit.cd_cidade });
        lawsuit.nm_cidade = lawsuit.ob_cidade?.nm_cidade;
        lawsuit.nm_estado = lawsuit.ob_cidade?.cd_estado?.nm_estado || "";
      }
    }

    await Promise.allSettled(lawsuits.map(retrieveCity));
  }

  async function extendsLawsuitsSystem(lawsuits: Lawsuit[]) {
    const response = await store.state.lawsuit.service.getProcessingSystems();
    const systems: ProcessingSystem[] = response?.data || [];

    lawsuits.forEach((lawsuit) => {
      lawsuit.ob_system = systems.find((system) => String(system.id) === String(lawsuit.cd_sistema_tramitacao));
    });
  }

  async function extendsLawsuitsForum(lawsuits: Lawsuit[]) {
    async function retrieveForums(lawsuits: Lawsuit[]) {
      const allForumIds = lawsuits.map((lawsuit) => lawsuit.cd_orgao);
      const forumIds = [...new Set(allForumIds.filter(Boolean))];

      if (!forumIds.length) {
        return {};
      }

      const forums: Forum[] = await store.state.forum.service.getAll({
        cd_foro: forumIds,
      });

      return forums.reduce((acc, forum) => {
        acc[String(forum.id)] = forum;
        return acc;
      }, {} as Record<string, Forum>);
    }

    const forums: Record<string, Forum> = await retrieveForums(lawsuits);

    lawsuits.forEach((lawsuit) => {
      lawsuit.ob_orgao = forums[String(lawsuit.cd_orgao)] || null;
    });
  }

  async function extendsLawsuits(lawsuits: Lawsuit[]) {
    await Promise.allSettled([
      extendsLawsuitsTags(lawsuits),
      extendsLawsuitsResponsible(lawsuits),
      extendsLawsuitsCity(lawsuits),
      extendsLawsuitsSystem(lawsuits),
      extendsLawsuitsForum(lawsuits),
    ]);
  }

  return {
    extendsLawsuits,
  };
}
