<template>
  <section class="c-dashboard-section-template">
    <slot name="header-container" v-if="useHeader">
      <div class="header">
        <slot name="title-container">
          <h4 class="title" v-if="$slots.title">
            <slot name="title">Titulo da seção</slot>
          </h4>
        </slot>

        <slot name="options-container" v-if="useOptions">
          <div class="options">
            <slot name="options-content">
              <slot name="options"></slot>

              <template v-if="useDefaultOptions">
                <p-button
                  v-if="useEdit"
                  class="p-button-warning edit"
                  label="Editar"
                  icon="fas fa-edit"
                  @click="$emit('click-edit')"
                />
                <p-button
                  v-if="useDelete"
                  class="p-button-danger delete"
                  icon="fas fa-trash-alt"
                  label="Deletar"
                  @click="$emit('click-delete')"
                />
              </template>
            </slot>
          </div>
        </slot>
      </div>
    </slot>

    <slot name="content-container">
      <slot name="fields-container" v-if="useFields">
        <div class="fields">
          <slot name="fields"></slot>
        </div>
      </slot>
    </slot>
  </section>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CDashboardSectionTemplate extends Vue {
  @Prop({ required: false, default: true }) readonly useHeader: any;
  @Prop({ required: false, default: true }) readonly useFields: any;
  @Prop({ required: false, default: false }) readonly useOptions: any;
  @Prop({ required: false, default: true }) readonly useEdit: any;
  @Prop({ required: false, default: true }) readonly useDelete: any;
  @Prop({ required: false, default: true }) readonly useDefaultOptions: any;
}
</script>

<style lang="scss" scoped>
.c-dashboard-section-template {
  width: 100%;

  .fields {
    display: flex;
    flex-wrap: wrap;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 36px;
    margin: 6px 0;

    .title {
      font-size: 1.2rem;
      font-weight: 500;
      color: var(--primary-color);
    }

    .options {
      .p-button {
        font-weight: 600;

        + .p-button {
          margin-left: 12px;
        }
      }
    }
  }
}
</style>
