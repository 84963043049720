import { RouteConfig } from "vue-router";

import logistics from "@/router/dashboard/logistics";
import debtor from "@/router/dashboard/collection";
import juridical from "@/router/dashboard/juridical";
import task from "@/router/dashboard/task";
import teams from "@/router/dashboard/teams";
import user from "@/router/dashboard/user";
import analytics from "@/router/dashboard/analytics";
import financial from "@/router/dashboard/financial";
import warning from "@/router/dashboard/warning";
import department from "@/router/dashboard/department";
import integration from "@/router/dashboard/integration";

const routes: RouteConfig = {
  path: "/dashboard",
  name: "dashboard",
  component: () => import("@/layouts/dashboard.vue"),
  redirect: () => ({ name: "home" }),
  meta: { requiresAuth: true },
  children: [
    {
      path: "/dashboard/home",
      name: "home",
      component: () => import("@/views/home.vue"),
    },
    {
      path: "/agenda",
      name: "home-calendar",
      component: () => import("@/views/calendar.vue"),
    },
    {
      path: "/dashboard/correspondente/progresso/:type",
      name: "correspondent-home",
      component: () => import("@/views/logistics/correspondent/home.vue"),
    },
    ...user,
    ...warning,
    ...debtor,
    ...logistics,
    ...task,
    ...juridical,
    ...teams,
    ...analytics,
    ...financial,
    ...department,
    ...integration,
  ],
};

export default routes;
