import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import gojus from "@/services/gojus";
import { generatePaginationOptions } from "@/utils/pagination";

import { Pagination, PaginationResponse } from "@/store/types";
import { IntegrationMovementT } from "./types";
import { IntegrationSystemT } from "../types";
import { Employee } from "@/store/department/employee/types";

const { VUE_APP_API_PROCESS_URL } = process.env;

@Module({ namespaced: true })
class IntegrationMovementModule extends VuexModule {
  list: IntegrationMovementT[] = [];
  pagination: Pagination = { row: 10, total: 0, current: 1 };

  summary: { [key: string]: any } = {};
  filters: any = { resumo: "PENDENTE-CIENCIA", attorney_id: null };

  @Mutation
  setList(movements: IntegrationMovementT[]) {
    this.list = movements;
  }

  @Mutation
  setPagination(payload: Partial<Pagination>) {
    this.pagination = { ...this.pagination, ...payload };
  }

  @Mutation
  setSummary(summary: { [key: string]: any }) {
    this.summary = summary;
  }

  @Mutation
  setFilters(value: any) {
    this.filters = { ...this.filters, ...value };
  }

  @Action
  async getList(payload?: { filters?: any }) {
    try {
      const filters = { ...this.filters, ...payload?.filters };
      if (filters.meus_processos) {
        filters.cd_responsavel_tarefa = this.context.rootState.user.current?.id;
      }

      const response = await gojus.get<PaginationResponse<IntegrationMovementT>>({
        entity: "/andamentos/",
        config: {
          query: { ...generatePaginationOptions(this.pagination), ...filters },
          baseAPI: VUE_APP_API_PROCESS_URL,
          useEstablishment: true,
        },
      });
      const movements = response?.data?.results || [];

      if (!this.context.rootState.integration.systems.length) {
        await this.context.dispatch("integration/getSystems", {}, { root: true });
      }
      const systems: IntegrationSystemT[] = this.context.rootState.integration.systems;

      const responsibleIds = [
        ...new Set(movements.map((item) => item.process_obj?.cd_responsavel_tarefa).filter(Boolean)),
      ];
      const responsibleList: Employee[] = [];
      if (responsibleIds.length) {
        const responsibleResponse = await this.context.dispatch(
          "department/employee/search",
          { cd_authuser: responsibleIds },
          { root: true }
        );
        responsibleList.push(...(responsibleResponse?.data?.results || []));
      }

      movements.forEach((movement) => {
        if (movement.process_obj?.id) {
          const system = systems.find((s) => s.id === Number(movement.process_obj?.cd_sistema_tramitacao));
          movement.system = system || null;

          const responsible = responsibleList.find(
            (r) => Number(r.cd_authuser) === Number(movement.process_obj?.cd_responsavel_tarefa)
          );
          movement.responsible = responsible || null;
        } else {
          movement.process_obj = null;
        }
      });

      this.context.commit("setPagination", { total: response?.data?.count || 0 });
      this.context.commit("setList", movements);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll(payload: { filters: any }) {
    try {
      const response = await gojus.get<IntegrationMovementT[]>({
        entity: "/andamentos/",
        config: {
          query: { ...payload.filters },
          baseAPI: VUE_APP_API_PROCESS_URL,
          useEstablishment: true,
        },
      });
      const movements = response?.data || [];

      movements.forEach((movement) => {
        if (!movement.process_obj?.id) {
          movement.process_obj = null;
        }
      });

      return movements;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return [];
    }
  }

  @Action
  async getSummary(payload?: { filters: any }) {
    try {
      const filters = {
        cd_responsavel_tarefa: this.context.rootState.user.current?.id,
        ...this.filters,
        ...payload?.filters,
      };

      const response = await gojus.get({
        entity: "/andamentos/card/",
        config: {
          query: { ...filters },
          baseAPI: VUE_APP_API_PROCESS_URL,
          useEstablishment: true,
        },
      });
      const summary = response?.data || {};
      this.context.commit("setSummary", summary);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async markAllAsReadByProcess(payload: { process: string }) {
    try {
      await gojus.get({
        entity: `/andamentos/ler/${payload.process}/`,
        config: {
          sentry: false,
          baseAPI: VUE_APP_API_PROCESS_URL,
        },
      });

      return { success: true };
    } catch {
      return { success: false };
    }
  }

  @Action
  async document(payload: { process: string; dt_movimento_inicial: string; filtro_dt_movimento: string }) {
    try {
      return await gojus.get({
        entity: "/doc-movements",
        config: {
          query: { ...payload },
          baseAPI: VUE_APP_API_PROCESS_URL,
        },
      });
    } catch {
      return null;
    }
  }
}

export default IntegrationMovementModule;
