import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/analytics",
    name: "analytics",
    redirect: { name: "analytics-dashboard" },
  },
  {
    path: "/analytics/dashboard",
    name: "analytics-dashboard",
    redirect: { name: "analytics-collection" },
    component: () => import("@/views/analytics/dashboard.vue"),
    children: [
      {
        path: "/analytics/cobranca",
        name: "analytics-collection",
        component: () => import("@/views/analytics/collection.vue"),
      },
      {
        path: "/analytics/tarefa",
        name: "analytics-task",
        component: () => import("@/views/analytics/task.vue"),
      },
      {
        path: "/analytics/financeiro",
        name: "analytics-financial",
        component: () => import("@/views/financial/analytics.vue"),
      },
      {
        path: "/analytics/juridico",
        name: "analytics-juridical",
        component: () => import("@/views/juridical/analytics.vue"),
      },
    ],
  },
  {
    path: "/analytics/devedores",
    name: "analytics-debtor",
    component: () => import("@/views/analytics/debtor.vue"),
  },
];

export default routes;
