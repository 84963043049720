import gojus from "@/services/gojus";
import { City, State } from "@/store/location/types";

const { VUE_APP_API_LOCATION_URL, VUE_APP_API_JURIDICAL_URL } = process.env;

export class LocationService {
  stateEndpoint = "estados/";
  stateCDEndpoint = (cd: number) => `estados/${cd}/`;
  stateRequestEndPoint = "audiencias/estados-solicitacao/";
  cityEndpoint = "cidades/";
  cityCDEndpoint = (cd: number) => `cidades/${cd}/`;
  districtEndpoint = "processos/list-comarcas/";

  async getStates() {
    const { data } = await gojus.get<State[]>({
      entity: this.stateEndpoint,
      config: { baseAPI: VUE_APP_API_LOCATION_URL },
    });
    return { data };
  }

  async getStatesRequest() {
    const { data } = await gojus.get<State[]>({
      entity: this.stateRequestEndPoint,
      config: { baseAPI: VUE_APP_API_LOCATION_URL },
    });
    return { data };
  }

  async getCities(cd_estado?: number) {
    const { data } = await gojus.get<City[]>({
      entity: this.cityEndpoint,
      config: {
        query: { cd_estado },
        baseAPI: VUE_APP_API_LOCATION_URL,
      },
    });

    return { data };
  }

  async getDistricts(nm_estado?: string) {
    const response = await gojus.get<{ lista: string[] }>({
      entity: this.districtEndpoint,
      config: {
        query: { nm_estado },
        baseAPI: VUE_APP_API_JURIDICAL_URL,
      },
    });

    const dicts = response?.data?.lista?.filter(Boolean) || [];

    return dicts;
  }

  async getCity(cd_cidade: number) {
    const { data } = await gojus.get<City>({
      entity: this.cityCDEndpoint(cd_cidade),
      config: { baseAPI: VUE_APP_API_LOCATION_URL },
    });

    return { data };
  }
}

export default LocationService;
