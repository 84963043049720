<template>
  <div class="c-modal-excel">
    <header class="header">
      <h5>Exportar processos</h5>
      <h6>
        Foram encontrados <b>{{ totalItems }}</b> processos no total.
      </h6>
    </header>

    <main class="content">
      <p-listbox
        :options="headerOptions.filter((header) => header.show)"
        optionLabel="label"
        dataKey="label"
        @change="$event.value.selected = !$event.value.selected"
      >
        <template #option="{ option }">
          <p-checkbox v-model="option.selected" :style="{ pointerEvents: 'none' }" :binary="true" />
          <label :style="{ marginLeft: '12px', cursor: 'pointer' }" v-text="option.label" />
        </template>
      </p-listbox>
    </main>

    <footer class="footer">
      <p class="export-alert">
        <i class="fas fa-triangle-exclamation"></i> Tempo estimado: {{ minutes }} minuto{{ minutes > 1 ? "s" : "" }}.
      </p>

      <div class="options">
        <p-button
          class="p-button-danger option"
          icon="fas fa-times-circle"
          label="Cancelar"
          @click="onAskingToCancel"
        />
        <p-button v-if="loading.items" class="p-button-success option" disabled>
          <p-progress-spinner strokeWidth="8" animationDuration="0.8s" />
          <span>Aguarde</span>
        </p-button>
        <p-button
          v-else
          class="p-button-success option"
          icon="fas fa-download"
          label="Baixar"
          @click="onAskingToDownload"
        />
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import PSlider from "primevue/slider/Slider.vue";
import PButton from "primevue/button/Button.vue";
import Listbox from "primevue/listbox";
import Checkbox from "primevue/checkbox";
import PProgressSpinner from "primevue/progressspinner/ProgressSpinner.vue";

import CFormFieldTemplate from "@/components/form/field.vue";

import { Lawsuit, ProcessingSystem } from "@/store/lawsuit/types";
import { formatDate, formatProcessNumberInCnjPattern } from "@/utils/formatters";

import { handleExportXLSX } from "@/plugins/excellentexport";
import { getActiveLawsuitStatus, getLogisticsCustomerPosition, getLawsuitRisk, getLawsuitType } from "@/utils/acronyms";

const PListbox: any = Listbox;
const PCheckbox: any = Checkbox;

@Component({
  components: { CFormFieldTemplate, PButton, PSlider, PListbox, PCheckbox, PProgressSpinner },
})
export default class CModalExcel extends Vue {
  @Prop({ required: true }) readonly props!: any;
  loading = { items: false };

  headers: string[] = [];
  content: string[][] = [];

  items: Lawsuit[] = [];
  totalItems = 0;
  pages = 0;
  minutes = 0;

  handleExportXLSX = handleExportXLSX;
  formatDate = formatDate;
  processingSystems: ProcessingSystem[] = [];

  headerOptions = [
    {
      label: "ID",
      handle: (item: Lawsuit) => item.id,
      selected: true,
      show: true,
    },
    {
      label: "Processo",
      handle: (item: Lawsuit) => formatProcessNumberInCnjPattern(item.nr_processo),
      selected: true,
      show: true,
    },
    {
      label: "Autor(es)",
      handle: (item: Lawsuit) => item.nm_autor,
      selected: true,
      show: true,
    },
    {
      label: "Réu(s)",
      handle: (item: Lawsuit) => item.nm_reu,
      selected: true,
      show: true,
    },
    {
      label: "Cidade",
      handle: (item: Lawsuit) => item.nm_cidade || item.ob_cidade?.nm_cidade || "",
      selected: true,
      show: true,
    },
    {
      label: "Estado",
      handle: (item: Lawsuit) => item.nm_estado || item.ob_cidade?.cd_estado?.nm_estado || "",
      selected: true,
      show: true,
    },
    {
      label: "Sistema de Tramitação",
      handle: (item: Lawsuit) => item.ob_system?.name,
      selected: true,
      show: true,
    },
    {
      label: "Foro",
      handle: (item: Lawsuit) => item.ob_orgao?.nm_foro,
      selected: true,
      show: true,
    },
    {
      label: "Instância",
      handle: (item: Lawsuit) => item.cd_instancia?.nm_instancia,
      selected: true,
      show: true,
    },
    {
      label: "Ação",
      handle: (item: Lawsuit) => item.ds_tipo_acao,
      selected: true,
      show: true,
    },
    {
      label: "Fase",
      handle: (item: Lawsuit) => item.cd_fase?.nm_fase,
      selected: true,
      show: true,
    },
    {
      label: "Area",
      handle: (item: Lawsuit) => item.cd_area?.nm_area,
      selected: true,
      show: true,
    },
    {
      label: "Citação",
      handle: (item: Lawsuit) => formatDate(item.dt_citacao),
      selected: true,
      show: true,
    },
    {
      label: "Encerramento",
      handle: (item: Lawsuit) => formatDate(item.dt_enceramento),
      selected: true,
      show: true,
    },
    {
      label: "Motivo do Encerramento",
      handle: (item: Lawsuit) => item.ds_motivo_encerramento || "",
      selected: true,
      show: true,
    },
    {
      label: "Distribuição",
      handle: (item: Lawsuit) => formatDate(item.dt_distribuicao),
      selected: true,
      show: true,
    },
    {
      label: "Recebimento (Escritório)",
      handle: (item: Lawsuit) => formatDate(item.dt_recebimento_processo_escritorio),
      selected: true,
      show: true,
    },
    {
      label: "Risco",
      handle: (item: Lawsuit) => getLawsuitRisk(item.ie_risco || "")?.label,
      selected: true,
      show: true,
    },
    {
      label: "Status",
      handle: (item: Lawsuit) => getActiveLawsuitStatus(item.ie_status || "AT").label,
      selected: true,
      show: true,
    },
    {
      label: "Judicial",
      handle: (item: Lawsuit) => getLawsuitType(Boolean(item.ie_judicial))?.label,
      selected: true,
      show: true,
    },
    {
      label: "Posição Cliente",
      handle: (item: Lawsuit) => getLogisticsCustomerPosition(String(item.ie_posicao_cliente))?.label,
      selected: true,
      show: true,
    },
    {
      label: "Liminar",
      handle: (item: Lawsuit) => (item.ie_liminar ? "Sim" : "Não"),
      selected: true,
      show: true,
    },
    {
      label: "Virtual",
      handle: (item: Lawsuit) => (item.ie_processo_virtual ? "Sim" : "Não"),
      selected: true,
      show: true,
    },
    {
      label: "Causa (R$)",
      handle: (item: Lawsuit) => item.nr_valor_causa?.toFixed(2),
      selected: true,
      show: true,
    },
    {
      label: "Condenação (R$)",
      handle: (item: Lawsuit) => item.nr_valor_condenacao?.toFixed(2),
      selected: true,
      show: true,
    },
    {
      label: "Sucumbência (R$)",
      handle: (item: Lawsuit) => item.nr_valor_sucumbencia?.toFixed(2),
      selected: true,
      show: true,
    },
    {
      label: "Recebido (R$)",
      handle: (item: Lawsuit) => item.nr_valor_pago_recebido?.toFixed(2),
      selected: true,
      show: true,
    },
    {
      label: "Perdido (R$)",
      handle: (item: Lawsuit) => item.nr_valor_perda?.toFixed(2),
      selected: true,
      show: true,
    },
  ];

  get filters() {
    return { ...this.$store.state.lawsuit.filters, ...this.$store.state.lawsuit.subtitleFilter };
  }

  async getProcessingSystems() {
    try {
      const response = await this.$store.state.lawsuit.service.getProcessingSystems();
      this.processingSystems = response?.data || [];
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
    }
  }

  async getProcessList(page: number) {
    const response = await this.$store.state.lawsuit.service.getAll(
      { nr_por_pagina: 1000, page, paginacao: true },
      this.filters
    );
    const processList = response?.data?.results || [];

    const forumList: { [key: number]: any } = {};
    const cityList: { [key: number]: any } = {};

    for (const lawsuit of processList) {
      const forumId = Number(lawsuit.cd_orgao);
      const cityId = Number(lawsuit.cd_cidade);

      if (forumId) forumList[forumId] = null;
      if (cityId) cityList[cityId] = null;
    }

    const forumPromises = Object.keys(forumList).map((_id) => this.$store.dispatch("forum/get", { cd: _id }));
    const cityPromises = Object.keys(cityList).map((_id) =>
      this.$store.dispatch("location/getCity", { cd_cidade: _id })
    );

    const [forumResults, cityResults] = await Promise.allSettled([
      Promise.allSettled(forumPromises),
      Promise.allSettled(cityPromises),
    ]);

    const forumResultItems = (
      forumResults.status === "fulfilled"
        ? forumResults.value.map((item) => (item.status === "fulfilled" ? item.value || null : null))
        : []
    ).filter((item) => item);
    const cityResultItems = (
      cityResults.status === "fulfilled"
        ? cityResults.value.map((item) => (item.status === "fulfilled" ? item.value || null : null))
        : []
    ).filter((item) => item);

    for (const forumItem of forumResultItems) {
      forumList[forumItem.id] = forumItem;
    }
    for (const cityItem of cityResultItems) {
      cityList[cityItem.id] = cityItem;
    }

    for (const lawsuit of processList) {
      const forumId = Number(lawsuit.cd_orgao);
      const cityId = Number(lawsuit.cd_cidade);

      lawsuit.ob_orgao = forumList[forumId] || null;
      lawsuit.ob_cidade = cityList[cityId] || null;
    }

    processList.forEach((lawsuit: any) => this.items.push(lawsuit));

    this.items.forEach((item) => {
      item.ob_system =
        this.processingSystems.find((system) => Number(system.id) === Number(item.cd_sistema_tramitacao)) || null;
    });
  }

  async onAskingToDownload() {
    this.loading.items = true;
    await this.getProcessingSystems();

    try {
      const selected = this.headerOptions.filter((item) => item.show && item.selected);

      for (let page = 1; page <= this.pages; page++) {
        await this.getProcessList(page);
      }

      this.headers = selected.map((header) => header.label);
      this.content = this.items.map((item) => selected.map((header) => header.handle(item)?.toString() || ""));

      handleExportXLSX(this.headers, this.content);
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
    } finally {
      this.loading.items = false;
    }
  }

  onAskingToCancel() {
    this.$store.commit("global/updateModalStatus", false);
  }

  created() {
    this.items = [];
    this.totalItems = this.$store.state.lawsuit.pagination.total;
    this.pages = Math.ceil(this.totalItems / 1000);
    this.minutes = Math.ceil(this.totalItems / 45 / 60);
  }
}
</script>

<style lang="scss">
.c-modal-excel {
  overflow-y: auto;
  max-height: 90vh;
  width: 100%;
  max-width: 480px;
  padding: 4px;

  .header {
    padding: 6px 12px 8px;

    h5 {
      font-size: 20px;
      line-height: 24px;
    }

    h6 {
      font-size: 13px;
      line-height: 20px;
      font-weight: 600;

      b {
        font-weight: 900;
      }
    }
  }

  .p-listbox {
    border: none;
  }

  .p-listbox-list {
    display: flex;
    flex-wrap: wrap;

    .p-listbox-item {
      width: 50%;
      padding: 6px 12px;
      border-radius: var(--border-radius-50);
    }
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #0000004d;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--info-color);
  }

  .footer {
    padding: 12px 12px 6px;

    .export-alert {
      margin-bottom: 24px;
      font-size: 13px;
      font-weight: 600;

      i {
        color: var(--warning-color);
        font-size: 14px;
        margin-right: 4px;
      }
    }

    .options {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      .p-button {
        flex: none;
        width: 110px;
        font-weight: 600;
        font-size: 14px;
        height: 34px;
        border-radius: var(--border-radius-50);
      }
    }
  }
}
</style>
