import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import gojus from "@/services/gojus";
import { generatePaginationOptions } from "@/utils/pagination";

import { Pagination, PaginationResponse } from "@/store/types";
import { IntegrationFoundFormT, IntegrationFoundT, IntegrationFoundTermFormT, IntegrationFoundTermT } from "./types";
import { useJuridicalAutomaticProcessRegistrationStore } from "@/stores/juridical/automatic-process-registration";
import { compareDates } from "@/utils/validators";

const { VUE_APP_API_INTEGRATION_URL } = process.env;

@Module({ namespaced: true })
class IntegrationFoundModule extends VuexModule {
  list: IntegrationFoundT[] = [];
  pagination: Pagination = { row: 10, total: 0, current: 1 };
  terms: IntegrationFoundTermT[] = [];

  summary: { [key: string]: any } = {};

  @Mutation
  setTerms(terms: IntegrationFoundTermT[]) {
    this.terms = terms;
  }

  @Mutation
  setSummary(summary: { [key: string]: any }) {
    this.summary = summary;
  }

  @Mutation
  setList(founds: IntegrationFoundT[]) {
    this.list = founds;
  }

  @Mutation
  setPagination(payload: Partial<Pagination>) {
    this.pagination = { ...this.pagination, ...payload };
  }

  @Action
  async getList(payload: { filters: any; commit?: boolean }) {
    try {
      const juridicalAutomaticProcessRegistrationStore = useJuridicalAutomaticProcessRegistrationStore();
      const response = await gojus.get<PaginationResponse<IntegrationFoundT>>({
        entity: "/novos-processos/movimentacoes/",
        config: {
          query: { ...generatePaginationOptions(this.pagination), ...payload.filters },
          baseAPI: VUE_APP_API_INTEGRATION_URL,
          useEstablishment: true,
        },
      });

      const items = response?.data?.results || [];
      const autoRegisterDate = juridicalAutomaticProcessRegistrationStore.state.retrieve.dt_criado;
      for (const item of items) {
        if (autoRegisterDate && item.created_at) {
          item.createdTask = compareDates(autoRegisterDate, item.created_at);
        }
      }

      if (payload.commit === false) {
        return { items: items, total: response?.data?.count || 0 };
      }

      this.context.commit("setPagination", { total: response?.data?.count || 0 });
      this.context.commit("setList", items);

      return response;
    } catch (error: any) {
      if (error?.status === 404) {
        this.context.commit("setPagination", { current: 1, row: 10, total: 0 });
        return await this.context.dispatch("getList", payload);
      }

      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getSummary() {
    try {
      const response = await gojus.get({
        entity: "/novos-processos/movimentacoes/contadores/",
        config: { baseAPI: VUE_APP_API_INTEGRATION_URL, useEstablishment: true },
      });
      this.context.commit("setSummary", response?.data || {});

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async editFound(payload: { id: number; form: Partial<IntegrationFoundFormT> }) {
    try {
      const response = await gojus.patch<IntegrationFoundT, Partial<IntegrationFoundFormT>>({
        entity: `/novos-processos/movimentacoes/${payload.id}/`,
        data: payload.form,
        config: { baseAPI: VUE_APP_API_INTEGRATION_URL, useEstablishment: true },
      });

      const found = this.list.find((found) => found.id === payload.id) || null;
      if (found) {
        if (payload.form.ie_baixado !== undefined) {
          found.ie_baixado = payload.form.ie_baixado ?? found.ie_baixado;
          found.baixado_em = response?.data?.baixado_em || found.baixado_em;
          found.baixado_por = response?.data?.baixado_por || found.baixado_por;
        }

        if (payload.form.ie_registrado !== undefined) {
          found.ie_registrado = payload.form.ie_registrado ?? found.ie_registrado;
        }
      }

      return { success: true };
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return { success: false };
    }
  }

  @Action
  async getTerms() {
    try {
      const response = await gojus.get<IntegrationFoundTermT[]>({
        entity: "/novos-processos/termos/",
        config: { baseAPI: VUE_APP_API_INTEGRATION_URL, useEstablishment: true },
      });

      this.context.commit("setTerms", response.data);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async createTerm(payload: { form: IntegrationFoundTermFormT }) {
    try {
      await gojus.post<IntegrationFoundTermT, IntegrationFoundTermFormT>({
        entity: "/novos-processos/termos/",
        data: payload.form,
        config: { baseAPI: VUE_APP_API_INTEGRATION_URL, useEstablishment: true },
      });

      this.context.dispatch("getTerms");

      return { success: true };
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return { success: false };
    }
  }

  @Action
  async removeTerm(payload: { id: number }) {
    try {
      await gojus.delete({
        entity: `/novos-processos/termos/${payload.id}/`,
        config: { baseAPI: VUE_APP_API_INTEGRATION_URL, useEstablishment: true },
      });

      this.context.dispatch("getTerms");

      return { success: true };
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
      return { success: false };
    }
  }
}

export default IntegrationFoundModule;
