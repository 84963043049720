import gojus from "@/services/gojus";

import { LawsuitCustomer, LawsuitCustomerContractForm, LawsuitCustomerFeesForm } from "@/store/lawsuit/customer/types";

import { removeMoneyMask, removeCPFMask, removeCNPJMask } from "@/utils/mask";
import { PaginationParams, PaginationResponse } from "@/store/types";

const { VUE_APP_API_JURIDICAL_URL } = process.env;

export class LawsuitCustomerService {
  endpoint = "clientes/";
  cdEndpoint = (cd: number) => `clientes/${cd}/`;

  async get(cd: number) {
    const { data } = await gojus.get<LawsuitCustomer>({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });
    return { data };
  }

  async getAll(pagination: PaginationParams | null, filters?: any) {
    const response = await gojus.get<PaginationResponse<LawsuitCustomer>>({
      entity: this.endpoint,
      config: {
        query: { ...(filters || {}), ...pagination },
        baseAPI: VUE_APP_API_JURIDICAL_URL,
      },
    });

    return response;
  }

  async create(customer: LawsuitCustomer) {
    const { data } = await gojus.post({
      entity: this.endpoint,
      data: this.handle(customer),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return { data };
  }

  async edit(cd: number, customer: LawsuitCustomer) {
    const { data } = await gojus.patch({
      entity: this.cdEndpoint(cd),
      data: this.handle(customer),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return { data };
  }

  async delete(cd: number) {
    const { data } = await gojus.delete({
      entity: this.cdEndpoint(cd),
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });
    return { data };
  }

  async editFeesType(cd: number, fees: LawsuitCustomerFeesForm[]) {
    const response = await gojus.patch({
      entity: this.cdEndpoint(cd),
      data: { cd_tipos_honorario: fees },
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  async editContracts(cd: number, contracts: LawsuitCustomerContractForm[]) {
    const response = await gojus.patch({
      entity: this.cdEndpoint(cd),
      data: { cd_vigencias_contratos: contracts },
      config: { baseAPI: VUE_APP_API_JURIDICAL_URL },
    });

    return response;
  }

  handle(customer: any): Partial<LawsuitCustomer> {
    if (customer.ie_ativo === false) {
      return { ie_ativo: false };
    }

    customer.nr_valor_advogado = parseFloat(removeMoneyMask(customer.nr_valor_advogado));
    customer.nr_valor_advogado_proposto = parseFloat(removeMoneyMask(customer.nr_valor_advogado_proposto));
    customer.nr_valor_proposto = parseFloat(removeMoneyMask(customer.nr_valor_proposto));
    customer.nr_valor_diligencia = parseFloat(removeMoneyMask(customer.nr_valor_diligencia));
    customer.nr_valor_audiencia = parseFloat(removeMoneyMask(customer.nr_valor_audiencia));

    if (customer.ie_tipo_cliente === "PF") {
      customer.nr_cpf = removeCPFMask(customer.nr_cpf);
      customer.nr_cnpj = "";
    } else {
      customer.nr_cnpj = removeCNPJMask(customer.nr_cnpj);
      customer.nr_cpf = "";
    }

    return customer;
  }
}

export default LawsuitCustomerService;
