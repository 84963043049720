import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ToastParams } from "@/store/global/types";
import { Employee, EmployeePayload } from "@/store/department/employee/types";

import { EmployeeService } from "@/store/department/employee/service";
import { Pagination, PaginationRow } from "@/store/types";

@Module({ namespaced: true })
export class EmployeeModule extends VuexModule {
  all: Employee[] = [];
  nonPaginatedList: Employee[] = [];
  current: Employee = { id: 0 };
  cache: { [cd_authuser: string]: Employee } = {};

  filters: any = {};
  pagination: Pagination = { row: 10, total: 0, current: 1 };

  service = new EmployeeService();

  @Mutation
  updateCurrent(employee: Employee) {
    this.current = employee;
  }

  @Mutation
  updateEmployees(employees: Employee[]) {
    this.all = employees;
  }

  @Mutation
  updateNonPaginatedEmployees(employees: Employee[]) {
    this.nonPaginatedList = employees;
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = value || {};
  }

  @Mutation
  updatePaginationRow(row: PaginationRow) {
    this.pagination.row = row;
  }

  @Mutation
  updatePaginationTotal(total: number) {
    this.pagination.total = total;
  }

  @Mutation
  updateCurrentPaginationPage(current: number) {
    this.pagination.current = current;
  }

  @Action
  async get(payload: EmployeePayload) {
    try {
      const { data: employee } = await this.service.get(payload.cd);

      if (this.context.rootState.user.permissions.managerModule && payload.getUser && employee.cd_authuser) {
        employee.user = (await this.context.dispatch("user/get", { cd: employee.cd_authuser }, { root: true })) || null;
      }

      if (employee.cd_naturalidade)
        employee.ob_naturalidade = await this.context.dispatch(
          "location/getCity",
          { cd_cidade: employee.cd_naturalidade },
          { root: true }
        );

      this.context.commit("updateCurrent", employee);

      return employee;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll(payload: any) {
    try {
      const response = await this.service.getAll(
        {
          page: this.pagination.current,
          nr_por_pagina: this.pagination.row,
          paginacao: true,
        },
        { ...this.filters, ...payload },
        payload?.showInactive
      );

      for (const employee of response.data.results) {
        if (employee.cd_naturalidade && payload?.getCity !== false) {
          employee.ob_naturalidade = await this.context.dispatch(
            "location/getCity",
            { cd_cidade: employee.cd_naturalidade },
            { root: true }
          );
        }
        if (employee.cd_authuser && payload?.getDetails !== false) {
          employee.qtd_prazos_responsavel = await this.context.dispatch(
            "task/responsibleTaskOnDay",
            { cd: employee.cd_authuser },
            { root: true }
          );
          employee.qtd_processos_responsavel = await this.context.dispatch(
            "lawsuit/responsibleLawsuit",
            { cd: employee.cd_authuser },
            { root: true }
          );
        }
        if (employee.cd_authuser) {
          this.cache[employee.cd_authuser] = employee;
        }
      }

      this.context.commit("updateEmployees", response.data.results);
      this.context.commit("updatePaginationTotal", response.data.count);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getNonPaginatedList(payload: any) {
    try {
      const response = await this.service.getAll<Employee[]>(
        null,
        { ...this.filters, ...payload },
        payload?.showInactive
      );

      for (const employee of response?.data || []) {
        if (employee.cd_authuser) {
          this.cache[employee.cd_authuser] = employee;
        }
      }

      this.context.commit("updateNonPaginatedEmployees", response.data);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async search(payload: any) {
    try {
      const response = await this.service.getAll(
        { page: 1, nr_por_pagina: 10, paginacao: true },
        { ...payload },
        payload?.showInactive
      );

      for (const employee of response.data.results) {
        if (payload.getTaskOnDay && employee.cd_authuser) {
          const taskOnDay = await this.context.dispatch(
            "task/responsibleTaskOnDay",
            { cd: employee.cd_authuser, dt_vencimento: payload.dt_vencimento },
            { root: true }
          );

          employee.prazos_processual = taskOnDay?.prazos_processual;
          employee.prazos_operacional = taskOnDay?.prazos_operacional;
          employee.qtd_prazos_responsavel = taskOnDay?.qtd_prazos_responsavel;
        }

        if (payload.getLawsuitNumber) {
          employee.qtd_processos_responsavel = await this.context.dispatch(
            "lawsuit/responsibleLawsuit",
            { cd: employee.cd_authuser },
            { root: true }
          );
        }
      }

      this.context.commit("updateEmployees", response.data.results);
      this.context.commit("updatePaginationTotal", response.data.count);

      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
      // return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async searchWithCache(payload: { filters: { cd_authuser: string[] } }) {
    try {
      const cachedEmployees = Object.entries(this.cache).filter(([cd_authuser]) =>
        payload.filters.cd_authuser.includes(cd_authuser)
      );
      if (cachedEmployees.length >= payload.filters.cd_authuser.length) {
        return cachedEmployees;
      }

      const response = await this.service.getAll<Employee[]>(null, { ...payload.filters }, true);
      const employees = response.data || [];

      for (const employee of employees) {
        if (employee.cd_authuser) {
          this.cache[employee.cd_authuser] = employee;
        }
      }

      return [...employees, ...cachedEmployees];
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
    }
  }

  @Action
  async create(payload: EmployeePayload) {
    try {
      let user = null;
      if (payload.form?.user) {
        const userResponse = await this.context.dispatch(
          "user/getAll",
          { filters: { username__iexact: payload.form.user.username, page: 1 } },
          { root: true }
        );
        if (userResponse?.results?.[0]) {
          user = userResponse.results[0];
        } else {
          user = await this.context.dispatch("user/create", { form: payload.form.user }, { root: true });
        }
      }

      if (user?.id) {
        payload.form.cd_authuser = user.id;
      }
      if (payload.form?.ie_ativo === undefined) {
        payload.form.ie_ativo = true;
      }

      const response = await this.service.create(payload.form);
      this.context.commit("updateCurrent", response.data);

      const message: ToastParams[] = [{ severity: "success", summary: "Colaborador cadastrado com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(payload: EmployeePayload) {
    try {
      if (payload.form?.user) {
        await this.context.dispatch(
          "user/edit",
          { cd: payload.form.cd_authuser, form: payload.form.user },
          { root: true }
        );
      }

      const response = await this.service.edit(payload.cd, payload.form);
      this.context.commit("updateCurrent", response.data);

      const message: ToastParams[] = [
        { severity: "success", summary: payload.successMessage || "Colaborador editado com sucesso!" },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async status(payload: { employeeId: number; authuserId: number; status: boolean; successMessage: string }) {
    try {
      if (payload.authuserId) {
        await this.service.status(payload.authuserId, payload.status);
      }

      const response = await this.service.edit(payload.employeeId, { ie_ativo: payload.status });
      this.context.commit("updateCurrent", response.data);

      const message: ToastParams[] = [
        { severity: "success", summary: payload.successMessage || "Colaborador editado com sucesso!" },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async remove(payload: { employeeId: number; authuserId: number }) {
    try {
      if (payload.authuserId) {
        await this.service.status(payload.authuserId, false);
      }

      const response = await this.service.remove(payload.employeeId);
      this.context.commit("updateCurrent", { id: 0 });

      const message: ToastParams[] = [{ severity: "success", summary: "Colaborador excluído com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default EmployeeModule;
